<template>
  <v-toolbar>
    <v-app-bar-nav-icon></v-app-bar-nav-icon>
    <v-toolbar-title>RideGreen.io</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-toolbar-items>
      <v-btn text>Dashboard</v-btn>
      <v-btn text>Meine Buchungen</v-btn>
      <v-btn text>Meine Nachrichten</v-btn>

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on"> Mein Konto </v-btn>
        </template>

        <v-list>
          <v-list-item @click="() => {}">
            <v-list-item-title>Profil bearbeiten</v-list-item-title>
          </v-list-item>
          <v-list-item @click="() => {}">
            <v-list-item-title>Sicherheit</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="n in 2" :key="n" @click="() => {}">
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
          <v-list-item color="warning" @click="logout()">
            <v-list-item-title><strong>Logout</strong></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-btn icon>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { TokenService } from "../services/storage.service.js";

export default {
  name: "NavHeader",
  methods: {
    logout() {
      console.log(TokenService.getToken());
      TokenService.removeToken();
      console.log("after LogOut", TokenService.getToken());
      this.$router.push("/");
      console.log("logged out");
    },
  },
};
</script>
