
import axios from 'axios';
import router from '../../router';
import { TokenService } from '../../services/storage.service';
const state = {
    isAuthenticated: false,
    authToken: null,
    userId: null,
    email: null,
    userProfile: null,
    userCategory: null,
    isDriver: false,
    isPassenger: false,
}

const getters = {
    isAuthenticated: state => state.isAuthenticated,
    getUserId: state => state.userId,
    getAuthToken(state) {
        if (state.authToken === null) {
            return TokenService.getToken();
        } else {
            return state.authToken;
        }
    },
    getUserCategory: state => state.userCategory,
}

const mutations = {
    SET_AUTH_TOKEN(state, token) {
        state.authToken = token;
        TokenService.saveToken(token);
    },
    SET_IS_AUTHENTICATED(state, value) {
        state.isAuthenticated = value;
    },
    SET_USER_ID(state, userId) {
        state.userId = userId;
    },
    SET_EMAIL(state, email) {
        state.email = email;
    },
    SET_USER_PROFILE(state, userProfile) {
        state.userProfile = userProfile;
    },
    SET_USER_CATEGORY(state, userCategory) {
        state.userCategory = userCategory;
    },
    SET_IS_DRIVER(state, value) {
        state.isDriver = value;
    },
    SET_IS_PASSENGER(state, value) {
        state.isPassenger = value;
    }
}

const actions = {
    me({ commit, rootGetters }) {
        axios.get("https://protected-garden-70662-418fed347d06.herokuapp.com/api/users/me/", {
            headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] }
        }).then(response => {
            const userId = response.data.profile.user;
            commit('SET_USER_ID', userId);
            commit('SET_USER_PROFILE', response.data.profile);
            commit('SET_USER_CATEGORY', response.data.profile.user_category);
            if (response.data.profile.user_category === "DR") {
                commit("SET_IS_DRIVER", true)
                commit("SET_IS_PASSENGER", false)

            } else if (response.data.profile.user_category === "CU") {
                commit("SET_IS_PASSENGER", true)
                commit("SET_IS_DRIVER", false)
            } else {
                commit("SET_IS_DRIVER", false)
                commit("SET_IS_PASSENGER", false)
                router.push({ name: 'about' })
            }
        })

    },
    login({ commit }, credentials) {
        const payload = {
            email: credentials.email,
            password: credentials.password,
        };
        const url = "https://protected-garden-70662-418fed347d06.herokuapp.com/"
        // const url = "http://127.0.0.1:8000/"
        const path = "auth/token/login/"

        axios.post(url + path, payload)
            .then(response => {
                const token = response.data.auth_token;
                console.log("Token", token);
                commit('SET_AUTH_TOKEN', token);
                commit('SET_IS_AUTHENTICATED', true);
            })
            .then(() => {
                // get user info
                const path = "api/users/me/"
                axios.get(url + path, { headers: { Authorization: "Token " + state.authToken } })
                    .then(response => {

                        commit('SET_EMAIL', response.data.email);
                        if (response.data.profile) {
                            const userId = response.data.profile.user;
                            commit('SET_USER_ID', userId);
                            commit('SET_USER_PROFILE', response.data.profile);
                            commit('SET_USER_CATEGORY', response.data.profile.user_category);
                            if (response.data.profile.user_category === "DR") {
                                commit("SET_IS_DRIVER", true)
                                commit("SET_IS_PASSENGER", false)
                                router.push({ name: 'DriverDashboard' })
                            } else if (response.data.profile.user_category === "CU") {
                                commit("SET_IS_PASSENGER", true)
                                commit("SET_IS_DRIVER", false)
                                router.push({ name: 'PassengerDashboard' })
                            } else {
                                commit("SET_IS_DRIVER", false)
                                commit("SET_IS_PASSENGER", false)
                                router.push({ name: 'about' })
                            }
                        }
                    })
            })
            .catch(error => {
                console.error(error);
            });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
