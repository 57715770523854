<template>
  <div>
    <NavHeader />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Neue Route erstellen</h1>
        </v-col>
        <v-col cols="12">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Start-/Zielort angeben
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Abfahrtszeiten anageben
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3"> Bestätigen </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card class="mb-12" outlined>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <p>Erstellen Sie hier Ihre neue Route</p>
                        <v-text-field
                          v-model="routeName"
                          outlined
                          label="Name der Route"
                          placeholder="Geben Sie den Namen Ihrer Route ein"
                          required
                        ></v-text-field>
                      </v-col>
                      <divider></divider>

                      <v-col cols="12">
                        <p>Wo fahren Sie los?</p>
                        <div class="shortcuts mb-2">
                          <template v-for="shortcut in shortcuts">
                            <v-chip
                              :key="shortcut.label"
                              color="blue"
                              small
                              text-color="white"
                              @click="fillStartAddress(shortcut.value)"
                              class="mr-1"
                            >
                              <v-icon> mdi-plus </v-icon>
                              {{ shortcut.label }}
                            </v-chip>
                          </template>
                        </div>
                        <v-card outlined>
                          <v-card-text>
                            <v-text-field
                              v-model="startStreet"
                              outlined
                              label="Strasse"
                              placeholder="Geben Sie die Strasse des Abfahrtsort ein"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="startPlz"
                              outlined
                              label="Postleitzahl"
                              placeholder="Geben Sie die Postleitzahl des Abfahrtsort ein"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="startCity"
                              outlined
                              label="Stadt"
                              placeholder="Geben Sie die Stadt des Abfahrtsort ein"
                              required
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col cols="12">
                        <p>Wo geht es hin?</p>
                        <div class="shortcuts mb-2">
                          <template v-for="shortcut in shortcuts">
                            <v-chip
                              :key="shortcut.label"
                              color="blue"
                              small
                              text-color="white"
                              @click="fillEndAddress(shortcut.value)"
                              class="mr-1"
                            >
                              <v-icon> mdi-plus </v-icon>
                              {{ shortcut.label }}
                            </v-chip>
                          </template>
                        </div>
                        <v-card outlined>
                          <v-card-text>
                            <v-text-field
                              v-model="endStreet"
                              outlined
                              label="Strasse"
                              placeholder="Geben Sie die Strasse des Abfahrtsort ein"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="endPlz"
                              outlined
                              label="Postleitzahl"
                              placeholder="Geben Sie die Postleitzahl des Abfahrtsort ein"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="endCity"
                              outlined
                              label="Stadt"
                              placeholder="Geben Sie die Stadt des Abfahrtsort ein"
                              required
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <div class="d-flex justify-space-between">
                  <v-btn color="primary" @click="e1 = 2"> Weiter </v-btn>
                  <router-link :to="{ name: 'DriverDashboard' }">
                    <v-btn class="text-right" text> Abbrechen </v-btn>
                  </router-link>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-12" outlined>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <p>Bieten Sie Hin- und Rückfahrt an?</p>
                        <v-switch
                          v-model="roundtrip"
                          inset
                          label="Hin und Rückfahrt anbieten?"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12">
                        <p>Wann fahren Sie los?</p>
                        <v-menu
                          v-model="startDateMenu"
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedStartDateFormatted"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              label="Datum"
                              placeholder="An welchem Tag geht es los?"
                              readonly
                              required
                              prepend-icon="mdi-calendar"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="startDate"
                            @input="startDateMenu = false"
                            locale="de"
                            format="dd.mm.yyyy"
                          ></v-date-picker>
                        </v-menu>

                        <v-dialog
                          ref="dialog1"
                          v-model="startTimeMenu"
                          :return-value.sync="startTime"
                          :nudge-right="40"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="startTime"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              label="Uhrzeit"
                              prepend-icon="mdi-clock-time-four-outline"
                              placeholder="Um wie viel Uhr geht es los?"
                              required
                              readonly
                            >
                            </v-text-field>
                          </template>
                          <v-time-picker
                            v-if="startTimeMenu"
                            v-model="startTime"
                            format="24hr"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="startTimeMenu = false"
                            >
                              Abbrechen
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog1.save(startTime)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col cols="12">
                        <p>Wann geht es zurück?</p>
                        <v-menu
                          v-model="returnDateMenu"
                          :close-on-content-click="true"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedReturnDateFormatted"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              label="Datum"
                              placeholder="An welchem Tag geht es los?"
                              readonly
                              required
                              prepend-icon="mdi-calendar"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="returnDate"
                            @input="returnDateMenu = false"
                            locale="de"
                            format="dd.mm.yyyy"
                          ></v-date-picker>
                        </v-menu>

                        <v-dialog
                          ref="dialog"
                          v-model="returnTimeMenu"
                          :return-value.sync="returnTime"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="returnTime"
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              label="Uhrzeit"
                              prepend-icon="mdi-clock-time-four-outline"
                              placeholder="Um wie viel Uhr geht es los?"
                              required
                              readonly
                            >
                            </v-text-field>
                          </template>
                          <v-time-picker
                            v-if="returnTimeMenu"
                            v-model="returnTime"
                            format="24hr"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="returnTimeMenu = false"
                            >
                              Abbrechen
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(returnTime)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <div class="d-flex justify-space-between">
                  <v-btn color="primary" @click="e1 = 3"> Weiter </v-btn>

                  <v-btn text @click="e1 = 1"> Zurück </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3">
                <p>Bitte überprüfen Sie Ihre Angaben:</p>
                <v-card class="mb-4" cols="12">
                  <v-row>
                    <v-col cols="3">
                      <v-img
                        :src="require('@/assets/card_image.png')"
                        style="max-width: 100%"
                      ></v-img>
                    </v-col>
                    <v-col cols="9">
                      <v-card-title>
                        <v-row>
                          <v-col cols="9">
                            <h3>{{ routeName }}</h3>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-subtitle>
                        <strong>Von:</strong> {{ startStreet }}, {{ startPlz }}
                        {{ startCity }}
                        <br />
                        <strong>Nach:</strong> {{ endStreet }}, {{ endPlz }}
                        {{ endCity }}
                      </v-card-subtitle>
                      <v-card-text>
                        <p>
                          <strong>Abfahrt um:</strong> {{ startTime }} am
                          {{ computedStartDateFormatted }}
                          <strong>Rückfahrt um:</strong>
                          {{ returnTime }} am {{ computedReturnDateFormatted }}
                        </p>
                        <div v-if="roundtrip">
                          <strong>Hin- und Rückfahrt</strong>
                        </div>
                        <div v-if="!roundtrip">
                          <strong>Einfache Fahrt</strong>
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="d-flex justify-space-between">
                  <v-btn color="primary" @click="submitRoute">
                    Route jetzt erstellen
                  </v-btn>

                  <v-btn class="text-right" text @click="e1 = 2">
                    Zurück
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NavHeader from "@/components/NavHeader.vue";

export default {
  name: "RouteCreateView",
  components: {
    NavHeader,
  },
  computed: {
    computedStartDateFormatted() {
      return this.formatDate(this.startDate);
    },
    computedReturnDateFormatted() {
      return this.formatDate(this.returnDate);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  data() {
    return {
      e1: 1,
      routeName: "",
      startStreet: "",
      startPlz: "",
      startCity: "",
      endStreet: "",
      endPlz: "",
      endCity: "",
      roundtrip: true,
      startDate: "",
      startDateMenu: false,
      startTime: "",
      startTimeMenu: false,
      returnDate: "",
      returnTime: "",
      returnDateMenu: false,
      returnTimeMenu: false,
      shortcuts: [
        { label: "ZF Werk Süd", value: "ZFSued" },
        { label: "ZF Werk Nord", value: "ZFNord" },
        { label: "ZF Aftermarket Service", value: "ZFAfterM" },
      ],
      addresses: {
        ZFNord: {
          street: "Ernst-Sachs-Straße 90",
          plz: "97424",
          city: "Schweinfurt",
        },
        ZFSued: {
          street: "Röntgenstraße 2",
          plz: "97424",
          city: "Schweinfurt",
        },
        ZFAfterM: {
          street: "Ob. Weiden 12",
          plz: "97424",
          city: "Schweinfurt",
        },
      },
    };
  },
  methods: {
    ...mapActions("routes", ["createRoute"]),
    ...mapActions("auth", ["me"]),
    submitRoute() {
      console.log("Route submitted");
      const payload = {
        routeName: this.routeName,
        startStreet: this.startStreet,
        startPlz: this.startPlz,
        startCity: this.startCity,
        endStreet: this.endStreet,
        endPlz: this.endPlz,
        endCity: this.endCity,
        roundtrip: this.roundtrip,
        startDate: this.computedStartDateFormatted,
        startTime: this.startTime,
        returnDate: this.computedReturnDateFormatted,
        returnTime: this.returnTime,
      };
      console.log(
        payload.startDate,
        payload.startTime,
        payload.returnDate,
        payload.returnTime
      );
      this.createRoute(payload);
    },

    fillStartAddress(adressKey) {
      const selectedAddress = this.addresses[adressKey];
      this.startStreet = selectedAddress.street;
      this.startPlz = selectedAddress.plz;
      this.startCity = selectedAddress.city;
    },
    fillEndAddress(adressKey) {
      const selectedAddress = this.addresses[adressKey];
      this.endStreet = selectedAddress.street;
      this.endPlz = selectedAddress.plz;
      this.endCity = selectedAddress.city;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  created() {
    this.me();
  },
};
</script>
