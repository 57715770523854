<template>
  <div>
    <NavHeader />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Dashboard</h1>
        </v-col>
      </v-row>
      <v-row v-if="!routes">
        <v-col cols="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="4">
          <v-card outlined class="text-center">
            <v-card-title>
              <h4 class="text-center">Gefahrene Kilometer</h4>
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="25"
                :value="55"
                color="success"
              >
                55 km
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="text-center">
            <v-card-title>
              <h4 class="text-center">Benzin Gespart</h4>
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="25"
                :value="20"
                color="deep-orange lighten-2"
              >
                4,75 l
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="text-center">
            <v-card-title>
              <h4 class="text-center">CO<sub>2</sub> Gespart</h4>
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="25"
                :value="20"
                color="indigo"
              >
                4,75 kg
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h2>Verfügbare Routen</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            append-icon="mdi-map-marker"
            label="Suchen"
            placeholder=""
            filled
            rounded
            dense
          ></v-text-field>
          <v-chip color="success" small
            ><v-icon left> mdi-plus </v-icon>Schweinfurt Hafen</v-chip
          >
          <v-chip color="success" small>ZF Werk Süd</v-chip>
          <v-chip color="success" small>ZF Werk Nord</v-chip>
          <div style="margin-top: 10px"></div>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-if="!routes">
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-for="route in routes" :key="route.uuid" cols="12">
          <v-card outlined>
            <v-row>
              <v-col cols="3">
                <v-img :src="require('@/assets/card_image.png')"></v-img>
              </v-col>
              <v-col cols="9">
                <v-card-title>
                  <v-row class="mb-2">
                    <v-col cols="9">
                      <h3>{{ route.name }}</h3>
                    </v-col>
                    <v-col cols="3">
                      <div
                        v-for="booking in route.bookings"
                        :key="booking.uuid"
                      >
                        <v-chip
                          v-if="
                            booking.user === getUserId && booking.status == 'PE'
                          "
                          color="yellow"
                          >Angefragt</v-chip
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <strong>Von:</strong> {{ route.point_of_departure.street }},
                  {{ route.point_of_departure.plz }}
                  {{ route.point_of_departure.city }} <strong>Nach:</strong>
                  {{ route.point_of_arrival.street }},
                  {{ route.point_of_arrival.plz }}
                  {{ route.point_of_arrival.city }}
                </v-card-subtitle>
                <v-card-text v-if="route.schedules[0]">
                  <p>
                    <strong>Abfahrt um:</strong>
                    {{
                      getGermanDateTime(route.schedules[0].departure_time_to)
                    }}

                    <strong>Rückfahrt um:</strong>
                    {{
                      getGermanDateTime(route.schedules[0].departure_time_back)
                    }}
                  </p>
                  <div v-if="route.schedules[0].is_roundtrip">
                    <strong>Hin- und Rückfahrt</strong>
                  </div>
                  <div v-if="route.schedules[0].is_singletrip">
                    <strong>Einfache Fahrt</strong>
                  </div>

                  <div v-if="route.stopovers.length > 0">
                    <strong>Zwischenstopps:</strong>
                    <ul>
                      <li
                        v-for="stopover in route.stopovers"
                        :key="stopover.uuid"
                      >
                        {{ stopover.street }}, {{ stopover.plz }}
                        {{ stopover.city }}
                      </li>
                    </ul>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    depressed
                    color="success"
                    @click="manageDialog(route.uuid)"
                    >Fahrt Buchen</v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="750">
      <v-card>
        <v-card-title class="headline">Fahrt buchen</v-card-title>
        <v-card-text
          >Kontaktieren Sie den Fahrer
          <v-textarea
            name="message"
            v-model="message"
            label="Nachricht"
            required
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submitEnquiry()">Absenden</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { HelperService } from "@/services/helpers.service.js";

export default {
  name: "PassengerDashboard",
  data() {
    return {
      dialog: false,
      valid: true,
      message: "",
      selectedRouteId: null,
    };
  },
  components: {
    NavHeader,
  },
  methods: {
    ...mapActions("routes", ["retrieveRoutes", "createRouteBooking"]),
    ...mapActions("auth", ["me"]),
    showEnquiryForm() {
      this.dialog = true;
      console.log("showEnquiryForm");
    },
    submitEnquiry(routeId) {
      console.log("submitEnquiry", routeId);
      this.createRouteBooking(this.selectedRouteId, this.message).then(() => {
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      });
      this.dialog = false;
      this.message = "";
      this.selectedRouteId = null;
    },
    checkBookings() {
      console.log("checkBookings");
      this.retrieveRoutes().then(() => {
        this.routes.forEach((route) => {
          if (route.bookings) {
            route.bookings.forEach((booking) => {
              if (booking.user === this.userId) {
                console.log("checkBookings", booking);
              }
            });
          }
        });
      });
    },
    bookingExists(routeId) {
      this.routes.find((route) => {
        if (route.uuid === routeId) {
          route.bookings.find((booking) => {
            if (booking.user === this.getUserId) {
              console.log("Found booking", booking, route.name);
              return true;
            }
          });
        }
      });
    },
    manageDialog(routeId) {
      this.dialog = true;
      this.selectedRouteId = routeId;
    },
  },
  computed: {
    ...mapGetters("auth", ["getUserId"]),
    ...mapState("routes", ["routes"]),
    ...mapState("auth", ["user"]),
    getGermanDateTime() {
      return (isoString) => {
        return HelperService.convertToGermanDateTime(isoString);
      };
    },
  },

  created() {
    this.me();
    this.retrieveRoutes();
    this.checkBookings();
  },
};
</script>
