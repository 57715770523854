<template>
  <div>
    <NavHeader />
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Fahrer-Dashboard</h1>
        </v-col>
      </v-row>
      <v-row v-if="!routes">
        <v-col cols="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="4">
          <v-card outlined class="text-center">
            <v-card-title>
              <h4 class="text-center">Gefahrene Kilometer</h4>
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="25"
                :value="66"
                color="success"
              >
                663 km
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="text-center">
            <v-card-title>
              <h4 class="text-center">Benzin Gespart</h4>
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="25"
                :value="55"
                color="deep-orange lighten-2"
              >
                75,30 l
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="text-center">
            <v-card-title>
              <h4 class="text-center">CO<sub>2</sub> Gespart</h4>
            </v-card-title>
            <v-card-text>
              <v-progress-circular
                :rotate="360"
                :size="150"
                :width="25"
                :value="40"
                color="indigo"
              >
                12,75 kg
              </v-progress-circular>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <h2>Meine Routen</h2>
        </v-col>
        <v-col cols="4" class="text-right">
          <router-link :to="{ name: 'RouteCreateView' }">
            <v-btn color="primary">
              <v-icon small>mdi-plus</v-icon>Neue Route erstellen</v-btn
            >
          </router-link>
        </v-col>
      </v-row>
      <v-row v-if="!routes">
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
        <v-col cols="12">
          <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-for="route in routes" :key="route.uuid" cols="12">
          <v-card outlined>
            <v-row>
              <v-col cols="3">
                <v-img :src="require('@/assets/card_image.png')"></v-img>
              </v-col>
              <v-col cols="9">
                <v-card-title>
                  <v-row class="mb-2">
                    <v-col cols="9">
                      <h3>{{ route.name }}</h3>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-subtitle>
                  <strong>Von:</strong> {{ route.point_of_departure.street }},
                  {{ route.point_of_departure.plz }}
                  {{ route.point_of_departure.city }} <strong>Nach:</strong>
                  {{ route.point_of_arrival.street }},
                  {{ route.point_of_arrival.plz }}
                  {{ route.point_of_arrival.city }}
                </v-card-subtitle>
                <v-card-text v-if="route.schedules[0]">
                  <p>
                    <strong>Abfahrt um:</strong>
                    {{
                      getGermanDateTime(route.schedules[0].departure_time_to)
                    }}

                    <strong>Rückfahrt um:</strong>
                    {{
                      getGermanDateTime(route.schedules[0].departure_time_back)
                    }}
                  </p>
                  <div v-if="route.schedules[0].is_roundtrip">
                    <strong>Hin- und Rückfahrt</strong>
                  </div>
                  <div v-if="route.schedules[0].is_singletrip">
                    <strong>Einfache Fahrt</strong>
                  </div>

                  <div v-if="route.stopovers.length > 0">
                    <strong>Zwischenstopps:</strong>
                    <ul>
                      <li
                        v-for="stopover in route.stopovers"
                        :key="stopover.uuid"
                      >
                        {{ stopover.street }}, {{ stopover.plz }}
                        {{ stopover.city }}
                      </li>
                    </ul>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed color="success"
                    ><v-icon small left>mdi-car</v-icon>Route bearbeiten</v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import NavHeader from "@/components/NavHeader.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { HelperService } from "@/services/helpers.service.js";
export default {
  name: "DriverDashboard",
  components: {
    NavHeader,
  },
  computed: {
    ...mapGetters("auth", ["getUserId"]),
    ...mapState("routes", ["routes"]),
    ...mapState("auth", ["user"]),

    getGermanDateTime() {
      return (isoString) => {
        return HelperService.convertToGermanDateTime(isoString);
      };
    },

    getISOString() {
      return (germanDateTime) => {
        return HelperService.convertToIsoString(germanDateTime);
      };
    },
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions("routes", ["retrieveRoutes"]),
    ...mapActions("auth", ["me"]),
  },
  created() {
    this.me();
    this.retrieveRoutes();
  },
};
</script>
