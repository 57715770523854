const TokenService = {
    getToken() {
        const theToken = localStorage.getItem('auth_token');
        return theToken;
    },
    saveToken(authToken) {
        localStorage.setItem('auth_token', authToken);
        localStorage.setItem('is_signed_in', true);
    },
    removeToken() {
        localStorage.removeItem('auth_token');
        localStorage.setItem('is_signed_in', false);
    },
    getIsSignedIn() {
        const flag = localStorage.getItem('is_signed_in');
        return flag;
    },
};

export {
    TokenService,
}