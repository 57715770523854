<template>
    <v-card outlined class="align-center justify-center">
        <v-card-title>
            <v-img :src="require('@/assets/RideGreen_Logo.png')"></v-img>
            <h3>Demo Login</h3>
        </v-card-title>
        <v-card-text>
            <v-form ref="loginForm" v-model="valid" @submit.prevent="submit">
                <v-text-field v-model="email" label="E-mail" required></v-text-field>
                <v-text-field v-model="password" type="password" label="Passwort" required></v-text-field>
                <v-btn block depressed color="primary" @click="submit">Login</v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'LoginForm',
    components: {
    },
    data() {
        return {
            email: '',
            password: '',
            valid: false,
        }
    },
    methods: {
        submit() {
            this.login({ email: this.email, password: this.password });
        },
        login() {
            if (this.$refs.loginForm.validate()) {
                this.$store.dispatch('login', {
                    email: this.email,
                    password: this.password
                })
            }
        },
        ...mapActions('auth', [
            'login',
        ]),
    },
}
</script>