import axios from 'axios';
// import router from '../../router';
import { HelperService } from '../../services/helpers.service.js';
import router from '@/router/index.js';



const state = {
    routes: null,
    detailRoute: null,
    routeBookingId: null,
    newRoute: null,
    wayPointStart: null,
    wayPointEnd: null,
}
const getters = {
    getRoutes: state => state.routes,
    getRouteBookingId: state => state.routeBookingId,
    getWayPointStart: state => state.wayPointStart,
    getWayPointEnd: state => state.wayPointEnd,
}
const mutations = {
    SET_ROUTES(state, routes) {
        state.routes = routes;
    },
    SET_ROUTE_BOOKING_ID(state, routeBookingId) {
        state.routeBookingId = routeBookingId;
    },
    SET_NEW_ROUTE(state, newRoute) {
        state.newRoute = newRoute;
    },
    SET_WAY_POINT_START(state, waypoint) {
        state.wayPointStart = waypoint;
    },
    SET_WAY_POINT_END(state, waypoint) {
        state.wayPointEnd = waypoint;
    }

}
const actions = {
    retrieveRoutes({ commit, rootGetters, rootState }) {
        console.log("Retrieveing Routes", rootState.auth.authToken)
        console.log("Retrieveing Routes 2", rootGetters["auth/getAuthToken"])
        return axios.get("https://protected-garden-70662-418fed347d06.herokuapp.com/api/routes/all/",
            { headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] } })
            .then(response => {
                const routes = response.data;
                commit('SET_ROUTES', routes);
            })
            .catch(error => {
                console.log(error);
            })
    },
    retrieveSingleRoute({ commit, rootGetters }, routeId) {
        return axios.get("https://protected-garden-70662-418fed347d06.herokuapp.com/api/routes/" + routeId + "/",
            { headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] } })
            .then(response => {
                const routes = response.data;
                commit('SET_ROUTES', routes);
            })
            .catch(error => {
                console.log(error);
            })
    },
    createRouteBooking({ commit, rootGetters }, routeId, message) {
        console.log("Route ID", routeId)
        const payload = {
            user: rootGetters["auth/getUserId"],
        }
        return axios.post("https://protected-garden-70662-418fed347d06.herokuapp.com/api/routebookings/", payload,
            { headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] } })
            .then(response => {
                const routeBookingId = response.data.uuid;
                commit('SET_ROUTE_BOOKING_ID', routeBookingId);
                console.log("Add booking to route", routeId, routeBookingId, message)
                axios.post("https://protected-garden-70662-418fed347d06.herokuapp.com/api/routebookings/add/",
                    { route_uuid: routeId, booking_uuid: routeBookingId, message: message },
                    { headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] } })
            })
            .catch(error => { console.error(error) })
    },
    createRoute({ commit, rootGetters }, payload) {
        /**
         * Create route: 
         * First send the waypoints and for start and return location
         * Then send the route details with the uuid for waypoints
         * Then send the route schedule
         */
        console.log("Create Route", payload)
        commit('SET_NEW_ROUTE', payload);
        const waypoint_start = {
            title: "Some title",
            description: "Some description",
            street: payload.startStreet,
            plz: payload.startPlz,
            city: payload.startCity,
            country: "DE",
            user: rootGetters["auth/getUserId"],
        }
        const waypoint_end = {
            title: "Some title",
            description: "Some description",
            street: payload.endStreet,
            plz: payload.endPlz,
            city: payload.endCity,
            country: "DE",
            user: rootGetters["auth/getUserId"],
        }



        return axios.post("https://protected-garden-70662-418fed347d06.herokuapp.com/api/routes/",
            {
                name: payload.routeName,
                user: rootGetters["auth/getUserId"],
                point_of_departure: waypoint_start,
                point_of_arrival: waypoint_end
            },
            { headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] } })
            .then(response => {
                console.log("Route created", response.data)
                axios.post("https://protected-garden-70662-418fed347d06.herokuapp.com/api/routeschedules/", {
                    route: response.data.uuid,
                    is_roundtrip: payload.roundTrip,
                    departure_time_to: HelperService.convertToIsoString(payload.startDate + " " + payload.startTime),//payload.startDate + "" + payload.startTime,
                    departure_time_back: HelperService.convertToIsoString(payload.returnDate + " " + payload.returnTime),//payload.returnDate + "" + payload.returnTime,
                    user: rootGetters["auth/getUserId"],
                },
                    { headers: { 'Authorization': 'Token ' + rootGetters["auth/getAuthToken"] } })
                    .then(response => {
                        console.log("Route schedule created", response.data)
                        router.push({ name: 'DriverDashboard' })
                    })
            })
    },
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};