import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DriverDashboard from '../views/DriverDashboard.vue'
import PassengerDashboard from '../views/PassengerDashboard.vue'
import RouteCreateView from '../views/RouteCreateView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/driver/dashboard',
    name: 'DriverDashboard',
    component: DriverDashboard
  },
  {
    path: '/passenger/dashboard',
    name: 'PassengerDashboard',
    component: PassengerDashboard
  },
  {
    path: '/routes/create',
    name: 'RouteCreateView',
    component: RouteCreateView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
