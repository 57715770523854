const HelperService = {
    convertToIsoString(germanDateTime) {
        // Split the date and time parts
       
        let [date, time] = germanDateTime.split(" ");

        // Split the date into day, month, and year  
        let [day, month, year] = date.split(".");

        // Split the time into hour and minute
        let [hour, minute] = time.split(":");

        // Create a new Date object. Note that the month in JavaScript is 0-indexed
        let jsDate = new Date(Date.UTC(year, month - 1, day, hour, minute));

        // Convert the JavaScript Date object to ISO string
        let isoString = jsDate.toISOString();

        return isoString;
    },

    convertToGermanDateTime(isoString) {
        // convert ISO string to string "hh:mm am dd.mm.yyyy"
        let jsDate = new Date(isoString);
        let germanTime = jsDate.toLocaleTimeString('de-DE', {
            hour: '2-digit',
            minute: '2-digit',
          });
        
          let germanDate = jsDate.toLocaleDateString('de-DE', {
            timeZone: 'UTC',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
        
          let germanDateTime = `${germanTime} am ${germanDate}`;
          return germanDateTime;
        
    }
};

export {
    HelperService,
}